import React, { useState, useEffect, Fragment } from 'react';
import {
  //   AccountCircle as AccountCircleIcon,
  Assessment as AssessmentIcon,
  Place as PlaceIcon,
  Build as BuildIcon,
  Commute as CommuteIcon,
  DriveEta as DriveEtaIcon,
  //   ExitToApp as ExitToAppIcon,
  //   GpsFixed as GpsFixedIcon,
  //   Group as GroupIcon,
  Home as HomeIcon,
  Map as MapIcon,
  SettingsEthernet as SettingsEthernetIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Business as BusinessIcon,
  TrendingDownOutlined as TrendingDownOutlinedIcon,
} from '@material-ui/icons';
import {
  LockReset as LockResetIcon,
  DepartureBoard as DepartureBoardIcon,

}
 from '@mui/icons-material';
import {
  ListItem as _ListItem,
  List as _List,
  ListItemIcon as _ListItemIcon,
  ListItemText,
  Box,
  Divider,
} from '@material-ui/core';
import { useLocation, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { IF } from 'utils/IF';
import { ADMIN, DRIVER, SUPER_ADMIN, COMPANY, RESELLER } from 'utils/roles';
import { RoleBased } from 'utils/RoleBased';
import { useTranslation } from 'react-i18next';

const drawerWidth = 320;

const List = styled(_List)({
  width: drawerWidth,
  padding: 0,
});

const ListItem = styled(_ListItem)({});

const ListItemIcon = styled(_ListItemIcon)({
  minWidth: '35px',
});

// const NestedListItem = styled(ListItem)({
//   paddingLeft: theme.spacing(6),
// });

export const MenuList = ({ handleDrawerClose }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(-1);
  const menu = [
    {
      text: t('menu.companies'), //Campanies Name change to Företag
      icon: <BusinessIcon />,
      path: '/companies',
      roles: {
        SUPER_ADMIN: SUPER_ADMIN,
        RESELLER: RESELLER,
      },
    },
    {
      text: t('menu.resellers'), //Resellers Name change to Återförsäljare
      icon: <TrendingDownOutlinedIcon />,
      path: '/resellers',
      roles: {
        SUPER_ADMIN: SUPER_ADMIN,
      },
    },
    {
      text: t('menu.map'), //Map Name change to Karta
      icon: <MapIcon />,
      path: '/map',
      roles: {
        COMPANY: COMPANY,
        ADMIN: ADMIN,
        DRIVER: DRIVER,
      },
    },
    {
      text: t('menu.routing'),
      icon: <MapIcon />,
      path: '/routing',
      roles: {
        ADMIN: ADMIN,
        COMPANY: COMPANY,
        DRIVER: DRIVER,
        // SUPER_ADMIN: SUPER_ADMIN,
        // RESELLER: RESELLER,
      },
    },
    {
      text: t('menu.organisation'), //Organisation Name change to Organisation
      icon: <SupervisorAccountIcon />,
      roles: {
        COMPANY: COMPANY,
        ADMIN: ADMIN,
        DRIVER: DRIVER,
        SUPER_ADMIN: SUPER_ADMIN,
      },
      submenu: [
        {
          text: t('menu.users'), //Users Name change to Användare
          icon: <SupervisorAccountIcon />,
          path: '/users',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
        {
          text: t('menu.vehicles'), //Vehicles Name change to Fordon
          icon: <DriveEtaIcon />,
          path: '/vehicles',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
        {
          text: t('menu.machines'), //Machines Name change to Maskiner
          icon: <BuildIcon />,
          path: '/machines',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
        {
          text: t('menu.tripologs'), //Tripologs Name change to Körjournal
          icon: <CommuteIcon />,
          path: '/tripologs',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
            DRIVER: DRIVER,
          },
        },
        {
          text: t('menu.Workinghour'), //Workinghour change to Arbetstider
          icon: <DepartureBoardIcon />,
          path: '/account/work-hour',
          roles: {
            DRIVER: DRIVER,
          },
        },
        {
          text: t('menu.changeCar'), //Change car change to Ändra fordon
          icon: <CommuteIcon />,
          path: '/account/change-car',
          roles: {
            DRIVER: DRIVER,
          },
        },
        {
          text: t('menu.ChangePassword'), //Change Password change to Ändra lösenord
          icon: <LockResetIcon />,
          path: '/account',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
            DRIVER: DRIVER,
            SUPER_ADMIN: SUPER_ADMIN,
          },
        },
      ],
    },
    {
      text: t('menu.alarm'), //Larm Name change to Larm
      icon: <SettingsEthernetIcon />,
      roles: {
        COMPANY: COMPANY,
        ADMIN: ADMIN,
      },
      submenu: [
        {
          text: t('menu.geofence'), //Geofence Name change to Säkerhetszon
          icon: <SettingsEthernetIcon />,
          path: '/geofence',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
        {
          text: t('menu.zoneLogs'), //zone logs
          icon: <SettingsEthernetIcon />,
          path: '/zonelogs',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
      ],
    },
    {
      text: t('menu.preselection'), //Preselection Name change to Förval
      icon: <PlaceIcon />,
      roles: {
        COMPANY: COMPANY,
        ADMIN: ADMIN,
      },
      submenu: [
        {
          text: t('menu.places'), //Places Name change to Platser
          icon: <PlaceIcon />,
          path: '/places',
          roles: {
            COMPANY: COMPANY,
            ADMIN: ADMIN,
          },
        },
      ],
    },
    {
      text: t('menu.economy'), //Economy Name change to Ekonomi
      icon: <AssessmentIcon />,
      roles: {
        COMPANY: COMPANY,
        ADMIN: ADMIN,
        SUPER_ADMIN: SUPER_ADMIN,
        // RESELLER: RESELLER,
        DRIVER: DRIVER,
      },
      submenu: [
        {
          text: t('menu.overview'), //Overview Name change to Översikt
          icon: <HomeIcon />,
          path: '/',
          roles: {
            ADMIN: ADMIN,
            COMPANY: COMPANY,
            SUPER_ADMIN: SUPER_ADMIN,
            RESELLER: RESELLER,
            DRIVER: DRIVER,
          },
        },

        {
          text: t('menu.reports'), //Reports Name change to Rapporter
          icon: <AssessmentIcon />,
          path: '/add-report',
          roles: {
            ADMIN: ADMIN,
            COMPANY: COMPANY,
            SUPER_ADMIN: SUPER_ADMIN,
            RESELLER: RESELLER,
          },
        },
      ],
    },
  ];

  const location = useLocation();

  useEffect(() => {
    menu.forEach((el, index) => {
      if (el.submenu) {
        const paths = el.submenu.map((el) => el.path);
        paths.forEach((path) => {
          if (path === location.pathname) {
            return setOpenMenu(index);
          }
        });
      }
    });
  }, [location.pathname]);

  const handleMenuToggler = (index) => {
    if (openMenu === index) {
      return setOpenMenu(-1);
    }

    setOpenMenu(index);
  };

  return (
    <List>
      {menu.map((el, index) => (
        <Fragment key={el.text}>
          <RoleBased {...el.roles}>
            <IF condition={el.submenu}>
              <Divider />
              <ListItem button onClick={() => handleMenuToggler(index)}>
                <ListItemText primary={<Box fontWeight={500}>{el.text}</Box>} />
              </ListItem>
            </IF>
            <IF condition={!el.submenu}>
              <ListItem
                button
                key={el.text}
                component={NavLink}
                to={el.path}
                onClick={handleDrawerClose}
                exact
                activeClassName="Mui-selected"
              >
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText primary={el.text} />
              </ListItem>
            </IF>
            <List component="div" disablePadding>
              <IF condition={el.submenu}>
                {el?.submenu?.map((subEl, index) => (
                  <RoleBased {...subEl.roles} key={index}>
                    <ListItem
                      button
                      key={subEl.text}
                      component={NavLink}
                      to={subEl.path}
                      exact
                      activeClassName="Mui-selected"
                      onClick={handleDrawerClose}
                    >
                      <ListItemIcon>{subEl.icon}</ListItemIcon>
                      <ListItemText primary={subEl.text} />
                    </ListItem>
                  </RoleBased>
                ))}
              </IF>
            </List>
          </RoleBased>
        </Fragment>
      ))}
    </List>
  );
};
