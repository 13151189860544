import http from './axios';
import httpRefresh from './axios-refresh';
import { DELETE, GET, POST, PUT } from './methods';

const headers = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
  };
};

export const loginRequest = ({ email, password }) => {
  return http({
    method: POST,
    url: '/auth/login',
    headers: headers(),
    data: {
      email,
      password,
    },
  });
};

export const testServerStatusRequest = () => {
  return http({
    method: GET,
    url: '/server-status',
    headers: headers(),
  });
};

export const generateReportRequest = ({
  format,
  type,
  driverId,
  startDate,
  endDate,
  picked,
}) => {
  return http({
    url: '/reports/generate-report',
    method: PUT,
    data: {
      format,
      type,
      driverId,
      startDate,
      endDate,
      picked,
    },
    responseType: 'blob',
    headers: headers(),
  });
};

export const saveReportDataRequest = (values) => {
  return http({
    method: POST,
    url: '/reports/save_report',
    responseType: 'json',
    headers: headers(),
    data: {
      ...values,
    },
  });
};

export const fetchTrackerDataRequest = (trackerImeis) => {
  return http({
    url: '/tracker/fetch-tracker-data',
    params: {
      trackerImeis,
    },
    headers: headers(),
  });
};

export const fetchAddressRequest = ({ latitude, longitude }) => {
  return http({
    method: GET,
    url: '/tracker/fetch-address',
    params: {
      latitude,
      longitude,
    },
    headers: headers(),
  });
};

export const fetchRolesRequest = () => {
  return http({
    url: '/role/fetch-roles',
    headers: {
      ...headers(),
      roleauthid: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
    },
  });
};

export const updatePasswordRequest = ({ password, confirmPassword }) => {
  return http({
    method: PUT,
    url: '/auth/update-password',
    headers: headers(),
    data: {
      password,
      confirmPassword,
    },
  });
};

export const loginAsUserRequest = ({ userId }) => {
  return http({
    method: POST,
    url: '/auth/login-as-user',
    headers: headers(),
    data: {
      userId,
    },
  });
};

export const personalDataRequest = () => {
  return http({
    url: '/auth/personal-data',
    headers: headers(),
  });
};

export const updateEmailRequest = ({ email, trackRestriction }) => {
  return http({
    method: PUT,
    url: '/auth/update-personal-data',
    headers: headers(),
    data: {
      email,
      trackRestriction,
    },
  });
};

export const createUserRequest = ({
  activeUser,
  name,
  lastname,
  password,
  email,
  role_id,
  vehicle_id,
  trackerName,
  trackerImei,
  phoneNumber,
  permissions,
  sendActivationEmail,
  expirationDate,
  renewDate,
  alarmDate,
  tripsOutsideWorkingHoursPrivate,
  sentEmailOutSideWorkingHour,
  workingDays,
  note,
  vehicleCategory,
}) => {
  return http({
    method: POST,
    url: '/user/create-user',
    headers: headers(),
    data: {
      activeUser,
      name,
      lastname,
      password,
      email,
      role_id,
      vehicle_id,
      trackerName,
      trackerImei,
      phoneNumber,
      permissions,
      sendActivationEmail,
      expirationDate,
      renewDate,
      alarmDate,
      tripsOutsideWorkingHoursPrivate,
      sentEmailOutSideWorkingHour,
      workingDays,
      note,
      vehicleCategory,
    },
  });
};

export const editUserRequest = (
  {
    activeUser,
    name,
    lastname,
    password,
    email,
    role_id,
    vehicle_id,
    trackerName,
    trackerImei,
    expirationDate,
    renewDate,
    alarmDate,
    isAlarmSent,
    phoneNumber,
    permissions,
    tripsOutsideWorkingHoursPrivate,
    sentEmailOutSideWorkingHour,
    workingDays,
    note,
    vehicleCategory
  },
  id
) => {
  return http({
    method: PUT,
    url: `/user/edit-user/${id}`,
    headers: headers(),
    data: {
      activeUser,
      name,
      lastname,
      password,
      email,
      role_id,
      vehicle_id,
      trackerName,
      trackerImei,
      expirationDate,
      renewDate,
      alarmDate,
      isAlarmSent,
      phoneNumber,
      permissions,
      tripsOutsideWorkingHoursPrivate,
      sentEmailOutSideWorkingHour,
      workingDays,
      note,
      vehicleCategory
    },
  });
};

export const fetchCompanyDriversRequest = () => {
  return http({
    url: `/user/fetch-company-drivers`,
    headers: headers(),
  });
};

export const fetchUsersRequest = () => {
  return http({
    url: '/user/fetch-users',
    headers: headers(),
  });
};

export const fetchUserByIdRequest = (id) => {
  return http({
    url: `/user/fetch-user/${id}`,
    headers: headers(),
  });
};

export const fetchUsersByCreatorIdRequest = (id) => {
  return http({
    url: `/user/fetch-by-creator/${id}`,
    headers: headers(),
  });
};

export const refreshTokenRequest = () => {
  return httpRefresh({
    method: POST,
    url: '/auth/refresh-token',
  });
};

export const deleteUserRequest = (id) => {
  return http({
    method: DELETE,
    url: `/user/delete-user/${id}`,
    headers: headers(),
  });
};

export const deleteCompanyRequest = (id) => {
  return http({
    method: DELETE,
    url: `/companies/delete-company/${id}`,
    headers: headers(),
  });
};

export const deleteResellerRequest = (id) => {
  return http({
    method: DELETE,
    url: `/resellers/delete-reseller/${id}`,
    headers: headers(),
  });
};

export const fetchManagersRequest = () => {
  return http({
    url: `/user/fetch-managers`,
    headers: headers(),
  });
};

export const createVehicleRequest = ({
  carType,
  name,
  meterReading,
  registrationPlate,
  fuelConsumption,
  model,
  mark,
  co2,
  checkupDate,
  checkupLocation,
  tax,
  taxDate,
  note,
}) => {
  return http({
    method: POST,
    url: `/vehicle/create-vehicle`,
    data: {
      carType,
      name,
      meterReading,
      registrationPlate,
      fuelConsumption,
      model,
      mark,
      co2,
      checkupDate,
      checkupLocation,
      tax,
      taxDate,
      note,
    },
    headers: headers(),
  });
};

export const fetchVehiclesRequest = () => {
  return http({
    url: `/vehicle/fetch-vehicles`,
    headers: headers(),
  });
};

export const fetchVehiclesDropdownRequest = ({ driverId }) => {
  return http({
    url: `/vehicle/fetch-vehicles-dropdown`,
    headers: headers(),
    params: {
      driverId,
    },
  });
};

export const editVehicleRequest = (
  {
    carType,
    name,
    meterReading,
    registrationPlate,
    fuelConsumption,
    model,
    mark,
    co2,
    tax,
    taxDate,
    checkupDate,
    checkupLocation,
    note,
  },
  id
) => {
  return http({
    method: PUT,
    url: `/vehicle/edit-vehicle/${id}`,
    data: {
      carType,
      name,
      meterReading,
      registrationPlate,
      fuelConsumption,
      model,
      mark,
      co2,
      tax,
      taxDate,
      checkupDate,
      checkupLocation,
      note,
    },
    headers: headers(),
  });
};

export const fetchDriversInMapRequest = () => {
  return http({
    url: `/user/fetch-drivers-in-map`,
    headers: headers(),
  });
};

export const fetchVehicleByIdRequest = (id) => {
  return http({
    url: `/vehicle/fetch-vehicle/${id}`,
    headers: headers(),
  });
};

export const deleteVehicleRequest = (id) => {
  return http({
    method: DELETE,
    url: `/vehicle/delete-vehicle/${id}`,
    headers: headers(),
  });
};

export const fetchTripologsRequest = async ({
  startTime,
  endTime,
  tripType,
  driverId,
}) => {
  return (
    await http({
      url: `/tripolog/fetch-tripologs`,
      params: {
        startTime,
        endTime,
        tripType,
        driverId,
      },
      headers: headers(),
    })
  )?.data?.tripologs;
};

export const addTripologNoteRequest = ({ id, note, tripType }) => {
  return http({
    url: `/tripolog/add-tripolog-note/${id}`,
    method: POST,
    data: {
      note,
      tripType,
    },
    headers: headers(),
  });
};

export const fetchCompaniesRequest = () => {
  return http({
    url: `/companies/fetch-companies`,
    headers: headers(),
  });
};

export const fetchResellersRequest = () => {
  return http({
    url: `/resellers/fetch-resellers`,
    headers: headers(),
  });
};

export const fetchCompanyByIdRequest = (id) => {
  return http({
    url: `/companies/fetch-company/${id}`,
    headers: headers(),
  });
};

export const fetchResellerByIdRequest = (id) => {
  return http({
    url: `/resellers/fetch-reseller/${id}`,
    headers: headers(),
  });
};

export const createCompanyRequest = ({
  activeUser,
  name,
  organizationNumber,
  taxNumber,
  address,
  postCode,
  postArea,
  email,
  phoneNumber,
  expirationDate,
  alarmDate,
  password,
  confirmPassword,
  sendActivationEmail,
  contactPersonName,
  contactPersonLastname,
  contactPersonEmail,
  contactPersonPhone,
}) => {
  return http({
    url: `/companies/create-company`,
    method: POST,
    data: {
      activeUser,
      name,
      organizationNumber,
      taxNumber,
      address,
      postCode,
      postArea,
      email,
      phoneNumber,
      expirationDate,
      alarmDate,
      password,
      confirmPassword,
      sendActivationEmail,
      contactPersonName,
      contactPersonLastname,
      contactPersonEmail,
      contactPersonPhone,
    },
    headers: headers(),
  });
};

export const editCompanyRequest = (
  {
    activeUser,
    name,
    organizationNumber,
    taxNumber,
    address,
    postCode,
    postArea,
    email,
    phoneNumber,
    expirationDate,
    isAlarmSent,
    alarmDate,
    password,
    confirmPassword,
    sendActivationEmail,
    contactPersonName,
    contactPersonLastname,
    contactPersonEmail,
    contactPersonPhone,
  },
  id
) => {
  return http({
    method: PUT,
    url: `/companies/edit-company/${id}`,
    headers: headers(),
    data: {
      activeUser,
      name,
      organizationNumber,
      taxNumber,
      address,
      postCode,
      postArea,
      email,
      phoneNumber,
      expirationDate,
      alarmDate,
      isAlarmSent,
      password,
      confirmPassword,
      sendActivationEmail,
      contactPersonName,
      contactPersonLastname,
      contactPersonEmail,
      contactPersonPhone,
    },
  });
};

export const editResellerRequest = (
  {
    activeUser,
    name,
    organizationNumber,
    taxNumber,
    address,
    postCode,
    postArea,
    email,
    phoneNumber,
    expirationDate,
    password,
    confirmPassword,
    contactPersonName,
    contactPersonLastname,
    contactPersonEmail,
    contactPersonPhone,
  },
  id
) => {
  return http({
    method: PUT,
    url: `/resellers/edit-reseller/${id}`,
    headers: headers(),
    data: {
      activeUser,
      name,
      organizationNumber,
      taxNumber,
      address,
      postCode,
      postArea,
      email,
      phoneNumber,
      expirationDate,
      password,
      confirmPassword,
      contactPersonName,
      contactPersonLastname,
      contactPersonEmail,
      contactPersonPhone,
    },
  });
};

export const createResellerRequest = ({
  activeUser,
  name,
  organizationNumber,
  taxNumber,
  address,
  postCode,
  postArea,
  email,
  phoneNumber,
  password,
  confirmPassword,
  sendActivationEmail,
  contactPersonName,
  contactPersonLastname,
  contactPersonEmail,
  contactPersonPhone,
}) => {
  return http({
    url: `/resellers/create-reseller`,
    method: POST,
    data: {
      activeUser,
      name,
      organizationNumber,
      taxNumber,
      address,
      postCode,
      postArea,
      email,
      phoneNumber,
      password,
      confirmPassword,
      sendActivationEmail,
      contactPersonName,
      contactPersonLastname,
      contactPersonEmail,
      contactPersonPhone,
    },
    headers: headers(),
  });
};

export const fetchVehicleRegistrationInfoRequest = (registrationPlate) => {
  return http({
    url: `/vehicle/fetch-vehicle-info/${registrationPlate}`,
    headers: headers(),
  });
};

export const fetchReportListRequest = () => {
  return http({
    url: '/reports/fetch-reports',
    headers: headers(),
  });
};

export const deleteReportRequest = (id) => {
  return http({
    method: DELETE,
    url: `/reports/delete-report/${id}`,
    headers: headers(),
  });
};

export const fetchVehicleAndTripsRequest = ({ startTime, endTime }) => {
  return http({
    method: GET,
    url: '/overview/fetch-vehicle-trips',
    headers: headers(),
    params: {
      startTime,
      endTime,
    },
  });
};

export const changeTripTypeRequest = ({
  id,
  note,
  tripType,
  notificationId,
}) => {
  return http({
    url: `/tripolog/change-tripType/${id}`,
    method: POST,
    data: {
      note,
      tripType,
      notificationId,
    },
    headers: headers(),
  });
};
export const fetchTripRequest = ({ id }) => {
  return http({
    url: `/tripolog/fetch-trip/${id}`,
    method: GET,
    headers: headers(),
  });
};
export const fetchAllInAppNotificationsRequest = (params) => {
  return http({
    method: GET,
    url: '/notification/fetch-all-inapp-notifications',
    headers: headers(),
    params: params,
  });
};
export const fetchNotificationsCountRequest = () => {
  return http({
    method: GET,
    url: '/notification/fetch-inapp-notifications-count',
    headers: headers(),
  });
};

export const resetPasswordRequest = ({ email }) => {
  return http({
    method: POST,
    url: '/auth/forgot-password',
    headers: headers(),
    data: {
      email,
    },
  });
};
export const changePasswordRequest = ({
  password,
  confirmPassword,
  userId,
  token,
}) => {
  return http({
    method: PUT,
    url: `/auth/reset-password/${userId}/${token}`,
    headers: headers(),
    data: {
      password,
      confirmPassword,
    },
  });
};

// export const fetchCompanyDriverForReportRequest = () => {
//   return http({
//     url: `/user/fetch-company-drivers-report`,
//     headers: headers(),
//   });
// };

export const previousTripRouteRequest = ({
  driverId,
  picked,
  startDate,
  endDate,
}) => {
  return http({
    url: '/routing/fetch-route-data',
    method: PUT,
    data: {
      driverId,
      picked,
      startDate,
      endDate,
    },
    headers: headers(),
  });
};

export const fetchRouteForSingleTripRequest = (id) => {
  return http({
    method: GET,
    url: '/routing/fetch-single-trip-route',
    params: {
      id,
    },
    headers: headers(),
  });
};

export const fetchShapesRequest = (id) => {
  return http({
    method: GET,
    url: `/geofencing/fetch-shapes`,
    params: {
      id,
    },
    headers: headers(),
  });
};
export const saveNewPolygonRequest = (polygons, newPolygons, id) => {
  return http({
    method: POST,
    url: `/geofencing/save-polygon/${id}`,
    data: {
      polygons,
      newPolygons,
    },
    headers: headers(),
  });
};

export const editPolygonsRequest = (editedPolygons, selectedDriver) => {
  return http({
    method: PUT,
    url: `/geofencing/edit-polygon/${selectedDriver}`,
    data: {
      editedPolygons,
    },
    headers: headers(),
  });
};

export const deletePolygonsRequest = (deletedPolygons, selectedDriver) => {
  return http({
    method: DELETE,
    url: `/geofencing/delete-polygon/${selectedDriver}`,
    data: {
      deletedPolygons,
    },
    headers: headers(),
  });
};

export const fetchZonelogsRequest = async ({
  startTime,
  endTime,
  driverId,
}) => {
  return http({
    url: `/geofencing/fetch-zonelogs`,
    params: {
      startTime,
      endTime,
      driverId,
    },
    headers: headers(),
  });
};

export const addCompanyInfoRequest = (
  {
    note,
    sendInvoice,
    invoiceDate,
    interval,
    intervalType,
    emailActive,
    inAppActive,
  },
  companyId
) => {
  return http({
    url: `/companies/create-company-info/${companyId}`,
    method: POST,
    data: {
      note,
      sendInvoice,
      invoiceDate,
      interval,
      intervalType,
      emailActive,
      inAppActive,
    },
    headers: headers(),
  });
};

export const fetchCompanyInfoByIdRequest = (id) => {
  return http({
    url: `/companies/fetch-company-info/${id}`,
    headers: headers(),
  });
};

export const editCompanyInfoRequest = (
  {
    note,
    sendInvoice,
    invoiceDate,
    interval,
    intervalType,
    emailActive,
    inAppActive,
  },
  infoId
) => {
  return http({
    method: PUT,
    url: `/companies/edit-company-info/${infoId}`,
    headers: headers(),
    data: {
      note,
      sendInvoice,
      invoiceDate,
      interval,
      intervalType,
      emailActive,
      inAppActive,
    },
  });
};

export const deleteMultipleCompanyRequest = (companyIds) => {
  return http({
    method: DELETE,
    url: `/companies/delete-multiple-company`,
    headers: headers(),
    data: {
      companyIds,
    },
  });
};

export const deleteMultipleUserRequest = (userIds) => {
  return http({
    method: DELETE,
    url: `/user/delete-multiple-user`,
    headers: headers(),
    data: {
      userIds,
    },
  });
};

export const deleteMultipleVehicleRequest = (vehicleIds) => {
  return http({
    method: DELETE,
    url: `/vehicle/delete-multiple-vehicle`,
    headers: headers(),
    data: {
      vehicleIds,
    },
  });
};

export const deleteMultipleReportRequest = (reportIds) => {
  return http({
    method: DELETE,
    url: `/reports/delete-multiple-report`,
    headers: headers(),
    data: {
      reportIds,
    },
  });
};

export const fetchUsersMapSettingRequest = () => {
  return http({
    url: `/user/fetch-drivers-map-setting`,
    headers: headers(),
  });
};

export const updateUsersMapSettingRequest = (mapSetting) => {
  return http({
    method: PUT,
    url: `/user/update-drivers-map-setting`,
    headers: headers(),
    data: {
      mapSetting,
    },
  });
};

export const changeTripTripTypeRequest = ({
  changeFrom,
  changeTo,
  addNote,
  note,
  tripIds,
}) => {
  return http({
    url: `/tripolog/change-triptype`,
    method: PUT,
    data: {
      changeFrom,
      changeTo,
      addNote,
      note,
      tripIds,
    },
    headers: headers(),
  });
};

export const logoutUserRequest = () => {
  return http({
    method: GET,
    url: '/auth/logout',
    headers: headers(),
  });
};

export const updateNotificationStatusToSeenRequest = ({ notificationId }) => {
  return http({
    url: `/notification/update-status`,
    method: PUT,
    data: {
      notificationId,
    },
    headers: headers(),
  });
};

export const UpdateMultipleStatusRequest = (status,userIds) => {
  return http({
    method: PUT,
    url: `/user/update-multiple-user-status`,
    headers: headers(),
    data: {
      userIds,
      status
    },
  });
};

export const UpdateMultiCompnyStatusRequest = (status,companyIds) => {
  return http({
    method: PUT,
    url: `/companies/update-multiple-company-status`,
    headers: headers(),
    data: {
      companyIds,
      status
    },
  });
};

export const UpdateOutSideWorkHourStatus = (status,userIds) => {
  return http({
    method: PUT,
    url: `/user/update-multiple-SentOutEmail-status`,
    headers: headers(),
    data: {
      userIds,
      status
    },
  });
};
